@import url('bootstrap-icons/font/bootstrap-icons');

:root{
  --main-color: #0e508b;
  --swiper-theme-color: #0e508b !important;
  --swiper-navigation-color: #0e508b;
}
html {
  scroll-behavior: smooth;
}
#main-navbar{
  --bs-bg-opacity: 1;
  background-color: var(--main-color) !important;
}
.main-color-icon:before{
  color: var(--main-color)
}
.text-kopsis{
  color: var(--main-color);
}
.grayed_background{
  background-color: lightgray;
}
.main-image{
  max-height: 500px;
}
.footer{
  --bs-bg-opacity: 1;
  background-color: var(--main-color) !important;
}
.img-block__1 {
  max-height: 350px;
  min-height: 350px;
  background-image: url('../images/gtp-main-image.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
}
.img_block__1__text_holder {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
}
.img_block__1__text_holder {
  background-color: #000000a1;
  padding: 20px 50px;
  border-radius: 20px;
}
.info-holder {
  background-color: var(--main-color);
  color: white;
  padding: 20px;
  border-radius: 15px;
}
.info_holder__text {
  min-height: 50px;
}
@media only screen and (max-width: 767px) {
  .img_block__1__text_holder {
    padding: 11px 10px;
    width: 85%;
  }
  .main_content__item__title h2{
    font-size: 19px;
  }
}
.title__border {
  margin-top: 20px;
  padding: 0;
  border: none;
  height: 4px;
  background-image: linear-gradient(271deg, rgba(14,80,139,0), rgba(14,80,139,0.75), rgba(14,80,139,0));
  color: #333;
  text-align: center;
  position: relative;
}
.title__border:after {
  content: " ";
  background: #fff url('../images/hr-image.png') no-repeat scroll center;
  width: 70px;
  height: 35px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  mix-blend-mode: darken;
  background-size: contain;
}
.main_content__item__title{
  margin-bottom: 40px;
}
.main_content__item__title h2, .main_content__item__title h2{
  color: var(--main-color);
  font-family: 'Helvetica Neue',sans-serif; 
  font-weight: bold; 
  letter-spacing: -1px; 
  line-height: 1; 
  text-align: center;
}
.dzi_partner_holder {
  background-color: #0e508b;
}
.grajdanska-main-content{
  background-color: #c9c9c9;
}
.swiper-item {
  position: relative;
  background: white;
  padding: 5px;
  -webkit-box-shadow: 0 1px 5px -1px rgb(0 0 0 / 13%);
  box-shadow: 0 1px 5px -1px rgb(0 0 0 / 13%);
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  min-height: 150px;
}
.swiper:not(.swiper-initialized) .swiper-slide:nth-child(n+2) { 
  display: none;
}
.swiper-slide img.img-fluid {
  max-height: 100px;
}